/***** -----------------------------
ページ読み込み時に処理 バアーモーダル用
----------------------------- ******/
$(function () {
  bannerModal();
});

//==========
//モーダル
//==========
function bannerModal() {
  const $modalClose = $(".js-popup-banner-close"),
    $modal = $(".js-popup-banner-target");

  // モーダルを閉じる処理
  $modalClose.on("click", function () {
    $modal.hide();

    // SP版の時トップページボタンの位置も修正
    if ($modal.hasClass("is-onlySP")) {
      $(".footer-pagetop-front").css("bottom", "20px");
    }
  });
}
